import { Route } from "@solidjs/router";
import { ROUTES } from "../const";
import { ShowSchedule } from "../views";
import { Ctrl } from ":mods";

export function TutorRoutes(props: Ctrl.Routes.model.TutorViewProps) {
  return (
    <>
      <Route path={ROUTES.foundation()} element={<ShowSchedule {...props} />} />
    </>
  );
}
